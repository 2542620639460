<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Assessment Criteria</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-4">
              <!-- advance serach -->
              <div class="d-flex">
                <div class="d-flex flex-column me-2">
                  <label class="col-form-label">Program</label>
                  <div style="width: 250px">
                    <v-select
                      label="name"
                      v-model="program"
                      :options="programList"
                      :clearable="false"
                      :selectable="(options) => program.id != options.id"
                      @option:selected="FilterData(program, level, status)"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex flex-column me-2">
                  <label class="col-form-label">Level</label>
                  <div style="width: 180px">
                    <v-select
                      label="name"
                      v-model="level"
                      :options="getLevel"
                      :clearable="false"
                      :selectable="(options) => level.id != options.id"
                      @option:selected="FilterData(program, level, status)"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <label class="col-form-label">Status</label>
                  <div style="width: 170px">
                    <v-select
                      label="name"
                      v-model="status"
                      :options="['Active', 'Inactive']"
                      :clearable="false"
                      :selectable="(options) => status != options"
                      @option:selected="FilterData(program, level, status)"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <!-- <div class="col-form-label d-none">hfhd</div> -->
                  <div @click="refreshData()" class="icon-css">
                    <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                  </div>
                </div>
              </div>
              <!--end advance serach -->
              <div class="d-flex justify-content-end mb-3">
                <router-link :to="{ name: 'assessment-criteria-create' }">
                  <button
                    v-show="!isLoading"
                    type="button"
                    class="btn btn-sm btn-blue waves-effect waves-light"
                  >
                    <i class="mdi mdi-plus-circle"></i> Add Criteria
                  </button>
                </router-link>
              </div>
              <place-holder v-if="isLoading"></place-holder>

              <DataTable
                v-else
                table="dataTable"
                :data="criteria"
                :columns="column"
                route="assessment-criteria-edit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../../components/shared/DataTable.vue";
import axios from "axios";
import $ from "jquery";
import { levels } from "../../../const";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";

// import { render } from 'vue';
export default {
  components: { DataTable, vSelect },

  data() {
    return {
      toast: useToast(),
      isLoading: true,
      num: 0,
      criteria: [],
      programList: [],
      getLevel: levels,
      level: "",
      program: "",
      status: "",
      baseUrl: process.env.VUE_APP_BASE_URL,

      column: [
        {
          data: "id",
          title: "No",
          render: () => {
            this.num++;
            return this.num;
          },
        },
        { data: "name", title: "Name" },
        {
          data: "programs",
          title: "Programs",
          render: (data) => {
            let str = "";
            for (let val of data) {
              str += val.code + ", ";
            }

            return str.slice(0, -2);
          },
        },
        { data: "level", title: "Level" },
        { data: "type", title: "Type" },
        {
          data: "point",
          title: "Point",
        },
        {
          data: null,
          title: "Status",
          render: (data) => {
            return data.status
              ? `<span class="badge bg-success p-1" style="width: 65px;">Active</span>`
              : `<span class="badge bg-danger p-1" style="width: 65px;">Inactive</span>`;
          },
        },
        {
          data: null,
          title: "Action",
          render: () => {
            return `<i class="mdi mdi-square-edit-outline edit-icon hover fs-4"></i>`;
          },
        },
      ],
    };
  },

  methods: {
    async getAllProgram() {
      await axios
        .get(`${this.baseUrl}admin/v1/programs`)
        .then((res) => (this.programList = res.data.data));
    },
    async getAllCriteria() {
      await axios
        .get(`${this.baseUrl}admin/v2/assessment-criteria`)
        .then((res) => {
          this.criteria = res.data.data;
          this.isLoading = false;
          $("#dataTable").DataTable().draw();
        })
        .catch(() => this.toast.error("Something Went Wrong!"));
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#dataTable").DataTable().destroy();
      $("#dataTable").DataTable().draw();
    },

    refreshData() {
      this.clearFilter();
      this.level = "";
      this.status = "";
      this.criteria = [];
      this.program = "";
      this.num = 0;
      this.getAllCriteria();
    },

    FilterData(program, level, status) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data) {
        const check_level = level?.name == data[3] ? true : false;
        const check_program = data[2].includes(program?.short_name)
          ? true
          : false;
        const check_status = status == data[6].trim() ? true : false;

        if (
          (check_level || !level.name) &&
          (check_status || !status) &&
          (check_program || !program.short_name)
        ) {
          return true;
        }

        return false;
      });

      $("#dataTable").DataTable().draw();
    },
  },

  async created() {
    await this.getAllCriteria();
    await this.getAllProgram();
  },
};
</script>
